<div class="pl-provider-assignments page-container">
  <div class="container-margins">
    <div class="header-container">
      <div class="assigments">
        <span class="header-tittle"> Assignments </span>
        <div class="header-text">
          <div class="break-line-margin">
            Manage your active, proposed, and pending assignments. For
            additional help, reach out to your Empowerment Manager,
            <span class="em-name">{{ empowermentManagerName }}.</span>
          </div>

          <ng-container *ngIf="!loading && !loadingAvailability">
            <span *ngIf="totalHoursProposed < maxWeeklyHours">
              Satisfied with your assignments?
              <span
                class="stop-proposals-text"
                (click)="updateMaxAvailableHours()"
                >Stop receiving proposals</span
              >
            </span>

            <span *ngIf="totalHoursProposed >= maxWeeklyHours">
              Need to adjust your requested hours?
              <a class="stop-proposals-text" [routerLink]="['/availability']"
                >Update your availability</a
              >
            </span>
          </ng-container>
        </div>
      </div>
      <div class="hours-card-container">
        <div class="hours-card">
          <div class="hours-number">
            {{ maxWeeklyHours | number : '1.0-2' }}
          </div>
          <div class="hours-type">Desired hours</div>
        </div>
        <div class="hours-card">
          <div class="hours-number">
            {{ totalHoursProposed | number : '1.0-2' }}
          </div>
          <div class="hours-type">Assigned hours</div>
        </div>
        <div class="hours-card">
          <div class="hours-number">
            {{ maxWeeklyHours - totalHoursProposed | number : '1.0-2' }}
          </div>
          <div class="hours-type">Remaining hours</div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!useAssignmentMarketplace"
      class="content-container cards-page-container"
    >
      <div *ngIf="loading" class="padding-xlarge-tb">
        <pl-dot-loader></pl-dot-loader>
      </div>
      <div [style.text-align]="'center'">
        <div *ngIf="!loading && !hasAssignments" class="empty-assignments">
          You have no assignments at this time
        </div>
      </div>
      <div *ngIf="!loading && hasAssignments" class="cards lanes">
        <div
          class="lane-1 lane proposed"
          *ngIf="assignments.length"
          [@plFadeInAnimation]
        >
          <div class="card-container" style="display: flex">
            <div
              class="card"
              *ngFor="let assignment of assignments"
              [ngClass]="assignmentStatusClasses[assignment.status]"
            >
              <div class="assigment-proposed">
                <span
                  class="text-proposal-status"
                  [ngClass]="assignmentStatusClasses[assignment.status]"
                >
                  {{ assignment.status | plAssigmentStatus }}
                </span>
                <div
                  *ngIf="assignment.status === assigmentStatusEnum.INITIATED"
                  class="expires-on"
                >
                  EXPIRES ON
                  {{ assignment.expiration_time | date : 'medium' }}
                </div>
              </div>
              <div class="card-data org-name">
                <div class="org-name-text">
                  {{ assignment.orgName }}
                </div>
                <div class="service-line-text">
                  {{ assignment.main_service_line_title | plServiceLineTitle }}
                  {{ assignment.isESY ? '(ESY)' : '' }}
                </div>
                <div class="card-divider"></div>
              </div>
              <div class="scroll">
                <div class="space-to-scroll">
                  <pl-missing-requirements-message
                    [status]="assignment.status"
                    [unmetRequirements]="assignment.unmetRequirements"
                  ></pl-missing-requirements-message>
                  <div>
                    <div class="card-data sub">
                      <div class="label">Rate</div>
                      ${{ assignment.payRate | number : '1.2-2' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label">Assignment type</span>
                      {{ assignment.assignment_type }}
                    </div>
                    <div class="card-data sub school-type">
                      <div class="label">School type</div>
                      {{ assignment.orgSchoolType | plCapitalizeFirst }}
                    </div>
                    <div
                      class="card-data sub"
                      *ngIf="assignment.specialties_label"
                    >
                      <span class="label">Specialty</span>
                      {{ assignment.specialties_label }}
                    </div>
                    <div class="card-data sub state">
                      <div class="label">State</div>
                      {{ assignment.state_full_name }}
                    </div>
                    <div
                      class="card-data sub timezone"
                      *ngIf="assignment.orgTimezone"
                    >
                      <span class="label">Time zone</span>
                      {{ assignment.orgTimezone }}
                    </div>
                    <div class="card-data sub est-dir-hours">
                      <span class="label">Estimated hours</span>
                      {{ assignment.estimatedHours | number : '1.1' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label"
                        >First work day
                        <pl-icon
                          class="blue-medium"
                          matTooltip="This is the day you start billing for this assignment"
                          matTooltipPosition="above"
                          matTooltipClass="pl-tooltip"
                          [svg]="'info'"
                          [height]="12"
                          [width]="12"
                          [verticalAlign]="'-2px'"
                        >
                        </pl-icon>
                      </span>
                      {{ assignment.startDate | date : 'MM/dd/yyyy' }}
                    </div>
                    <div class="card-data sub">
                      <span class="label">Therapy start date</span>
                      {{
                        assignment.projected_therapy_start_date
                          | date : 'MM/dd/yyyy'
                      }}
                    </div>
                    <div class="card-data sub missing-req">
                      <span class="label">End date</span>
                      {{ assignment.endDate | date : 'MM/dd/yyyy' }}
                    </div>
                    <div class="card-data sub missing-req">
                      <span class="label">Presence contact</span>
                      {{ assignment.clinical_success_manager }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-buttons">
                <!-- TO DO REMOVE ngIf after the view details is implemented -->
                <button mat-raised-button color="primary" *ngIf="false">
                  View details
                </button>
                <button
                  *ngIf="
                    assignment.status === assigmentStatusEnum.INITIATED ||
                    assignment.status === assigmentStatusEnum.TENTATIVE
                  "
                  mat-stroked-button
                  color="primary"
                  type="button"
                  (click)="onClickDecline(assignment)"
                  [disabled]="saving"
                >
                  Decline
                </button>
                <button
                  *ngIf="assignment.status === assigmentStatusEnum.INITIATED"
                  mat-flat-button
                  color="primary"
                  type="button"
                  (click)="onClickAccept(assignment)"
                  [disabled]="saving"
                >
                  Accept
                </button>
                <button
                  *ngIf="assignment.status === assigmentStatusEnum.RESERVED"
                  mat-stroked-button
                  color="primary"
                  type="button"
                  (click)="openContactClsmDialog(assignment)"
                  [disabled]="assignment.is_on_hold"
                >
                  {{
                    assignment.is_on_hold ? 'CLSM contacted' : 'Contact CLSM'
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-tab-group
      *ngIf="useAssignmentMarketplace"
      (selectedTabChange)="loadAssignmentMarketplaceDemands($event)"
      animationDuration="0ms"
      [selectedIndex]="0"
    >
      <mat-tab label="My Assignments">
        <div class="content-container cards-page-container">
          <div *ngIf="loading" class="padding-xlarge-tb">
            <pl-dot-loader></pl-dot-loader>
          </div>
          <div [style.text-align]="'center'">
            <div *ngIf="!loading && !hasAssignments" class="empty-assignments">
              You have no assignments at this time
            </div>
          </div>
          <div *ngIf="!loading && hasAssignments" class="cards lanes">
            <div
              class="lane-1 lane proposed"
              *ngIf="assignments.length"
              [@plFadeInAnimation]
            >
              <div class="card-container" style="display: flex">
                <div
                  class="card"
                  *ngFor="let assignment of assignments"
                  [ngClass]="assignmentStatusClasses[assignment.status]"
                >
                  <div class="assigment-proposed">
                    <span
                      class="text-proposal-status"
                      [ngClass]="assignmentStatusClasses[assignment.status]"
                    >
                      {{ assignment.status | plAssigmentStatus }}
                    </span>
                    <div
                      *ngIf="
                        assignment.status === assigmentStatusEnum.INITIATED
                      "
                      class="expires-on"
                    >
                      EXPIRES ON
                      {{ assignment.expiration_time | date : 'medium' }}
                    </div>
                  </div>
                  <div class="card-data org-name">
                    <div class="org-name-text">
                      {{ assignment.orgName }}
                    </div>
                    <div class="service-line-text">
                      {{
                        assignment.main_service_line_title | plServiceLineTitle
                      }}
                      {{ assignment.isESY ? '(ESY)' : '' }}
                    </div>
                    <div class="card-divider"></div>
                  </div>
                  <div class="scroll">
                    <div class="space-to-scroll">
                      <pl-missing-requirements-message
                        [status]="assignment.status"
                        [unmetRequirements]="assignment.unmetRequirements"
                      ></pl-missing-requirements-message>
                      <div>
                        <div class="card-data sub">
                          <div class="label">Rate</div>
                          ${{ assignment.payRate | number : '1.2-2' }}
                        </div>
                        <div class="card-data sub">
                          <span class="label">Assignment type</span>
                          {{ assignment.assignment_type }}
                        </div>
                        <div class="card-data sub school-type">
                          <div class="label">School type</div>
                          {{ assignment.orgSchoolType | plCapitalizeFirst }}
                        </div>
                        <div
                          class="card-data sub"
                          *ngIf="assignment.specialties_label"
                        >
                          <span class="label">Specialty</span>
                          {{ assignment.specialties_label }}
                        </div>
                        <div class="card-data sub state">
                          <div class="label">State</div>
                          {{ assignment.state_full_name }}
                        </div>
                        <div
                          class="card-data sub timezone"
                          *ngIf="assignment.orgTimezone"
                        >
                          <span class="label">Time zone</span>
                          {{ assignment.orgTimezone }}
                        </div>
                        <div class="card-data sub est-dir-hours">
                          <span class="label">Estimated hours</span>
                          {{ assignment.estimatedHours | number : '1.1' }}
                        </div>
                        <div class="card-data sub">
                          <span class="label"
                            >First work day
                            <pl-icon
                              class="blue-medium"
                              matTooltip="This is the day you start billing for this assignment"
                              matTooltipPosition="above"
                              matTooltipClass="pl-tooltip"
                              [svg]="'info'"
                              [height]="12"
                              [width]="12"
                              [verticalAlign]="'-2px'"
                            >
                            </pl-icon>
                          </span>
                          {{ assignment.startDate | date : 'MM/dd/yyyy' }}
                        </div>
                        <div class="card-data sub">
                          <span class="label">Therapy start date</span>
                          {{
                            assignment.projected_therapy_start_date
                              | date : 'MM/dd/yyyy'
                          }}
                        </div>
                        <div class="card-data sub missing-req">
                          <span class="label">End date</span>
                          {{ assignment.endDate | date : 'MM/dd/yyyy' }}
                        </div>
                        <div class="card-data sub missing-req">
                          <span class="label">Presence contact</span>
                          {{ assignment.clinical_success_manager }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="action-buttons">
                    <!-- TO DO REMOVE ngIf after the view details is implemented -->
                    <button mat-raised-button color="primary" *ngIf="false">
                      View details
                    </button>

                    <button
                      *ngIf="
                        assignment.status === assigmentStatusEnum.INITIATED ||
                        assignment.status === assigmentStatusEnum.TENTATIVE
                      "
                      mat-stroked-button
                      color="primary"
                      type="button"
                      (click)="onClickDecline(assignment)"
                      [disabled]="saving"
                    >
                      Decline
                    </button>
                    <button
                      *ngIf="
                        assignment.status === assigmentStatusEnum.INITIATED
                      "
                      mat-flat-button
                      color="primary"
                      type="button"
                      (click)="onClickAccept(assignment)"
                      [disabled]="saving"
                    >
                      Accept
                    </button>

                    <button
                      *ngIf="assignment.status === assigmentStatusEnum.RESERVED"
                      mat-stroked-button
                      color="primary"
                      type="button"
                      (click)="openContactClsmDialog(assignment)"
                      [disabled]="assignment.is_on_hold"
                    >
                      {{
                        assignment.is_on_hold
                          ? 'CLSM contacted'
                          : 'Contact CLSM'
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Assignment Marketplace">
        <div class="content-container cards-page-container">
          <div *ngIf="loading" class="padding-xlarge-tb">
            <pl-dot-loader></pl-dot-loader>
          </div>
          <div [style.text-align]="'center'">
            <div
              *ngIf="!loading && !hasMarketplaceAssignments"
              class="empty-assignments"
            >
              You have no assignments at this time
            </div>
          </div>
          <div
            *ngIf="!loading && hasMarketplaceAssignments"
            class="cards lanes"
          >
            <div
              class="lane-1 lane proposed"
              *ngIf="marketPlaceAssignments.length"
              [@plFadeInAnimation]
            >
              <div class="card-container" style="display: flex">
                <div
                  class="card open-opp"
                  *ngFor="let demand of marketPlaceAssignments"
                >
                  <div class="assigment-proposed">
                    <span class="text-proposal-status open-opp">
                      OPEN OPPORTUNITY
                    </span>
                  </div>
                  <div class="card-data org-name">
                    <div class="org-name-text">
                      {{ demand.organization_name }}
                    </div>
                    <div class="card-divider"></div>
                  </div>
                  <div class="scroll">
                    <div class="space-to-scroll">
                      <div>
                        <div class="card-data sub">
                          <span class="label">Assignment type</span>
                          {{
                            demand.service_model === 'DH'
                              ? 'Dedicated Services'
                              : 'Hourly'
                          }}
                        </div>
                        <div class="card-data sub school-type">
                          <div class="label">School type</div>
                          {{ demand.school_type | plCapitalizeFirst }}
                        </div>
                        <div class="card-data sub state">
                          <div class="label">State</div>
                          {{ demand.state }}
                        </div>
                        <div
                          class="card-data sub timezone"
                          *ngIf="demand.timezone"
                        >
                          <span class="label">Time zone</span>
                          {{ demand.timezone }}
                        </div>
                        <div class="card-data sub est-dir-hours">
                          <span class="label">Assignment hours</span>
                          {{ demand.unfulfilled_hours | number : '1.1' }}
                        </div>
                        <div class="card-data sub">
                          <span class="label"
                            >First work day
                            <pl-icon
                              class="blue-medium"
                              matTooltip="This is the day you start billing for this assignment"
                              matTooltipPosition="above"
                              matTooltipClass="pl-tooltip"
                              [svg]="'info'"
                              [height]="12"
                              [width]="12"
                              [verticalAlign]="'-2px'"
                            >
                            </pl-icon>
                          </span>
                          {{ demand.start_date | date : 'MM/dd/yyyy' }}
                        </div>
                        <div class="card-data sub">
                          <span class="label">Therapy start date</span>
                          {{ demand.therapy_start_date | date : 'MM/dd/yyyy' }}
                        </div>
                        <div class="card-data sub missing-req">
                          <span class="label">End date</span>
                          {{ demand.end_date | date : 'MM/dd/yyyy' }}
                        </div>
                        <div
                          *ngIf="demand.is_mhc_pes || demand.is_slp_ot"
                          class="card-data sub missing-req"
                        >
                          <span class="label">Presence contact</span>
                          <a
                            *ngIf="demand.is_mhc_pes"
                            [href]="
                              'mailto:' +
                              demand.clinical_success_manager_pes_mhc_email
                            "
                          >
                            {{ demand.clinical_success_manager_pes_mhc }}
                          </a>
                          <a
                            *ngIf="demand.is_slp_ot"
                            [href]="
                              'mailto:' +
                              demand.clinical_success_manager_slp_ot_email
                            "
                          >
                            {{ demand.clinical_success_manager_slp_ot }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="action-buttons">
                    <ng-container>
                      <button
                        mat-flat-button
                        color="primary"
                        type="button"
                        (click)="onClickAcceptMarketplaceDemand(demand)"
                        [disabled]="saving"
                      >
                        Accept
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
