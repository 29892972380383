import dayjs from 'dayjs';
import durationPlugin, { DurationUnitsObjectType } from 'dayjs/plugin/duration';

import { AssignmentProposal } from '@common/assigment-machine/models';
import { SPECIALTIES_LONG_NAME } from '@common/assigment-machine/specialties.constants';
import { CalendarAssignmentProposal, PLEvent } from '../models';

dayjs.extend(durationPlugin);

export function parseDurationString(duration: string): DurationUnitsObjectType {
  // Initialize days to 0
  let days = '0';
  let time = duration;

  // Check if the duration string contains a space
  if (duration.includes(' ')) {
    // Split the duration string into days and time parts
    [days, time] = duration.split(' ');
  }

  // Split the time part into hours, minutes, and seconds
  const [hours, minutes, seconds] = time.split(':');

  // Return the duration in ISO 8601 format
  return {
    days: parseInt(days),
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    seconds: parseInt(seconds),
  };
}

export function createServiceModelLabel(
  serviceModel: CalendarAssignmentProposal['service_model'],
) {
  if (serviceModel === 'DH') {
    return 'Dedicated';
  }

  return 'Hourly';
}

interface CreateAssignmentCalendarLabelOptions {
  showHours?: boolean;
  showDates?: boolean;
}

export function createAssignmentCalendarLabel(
  assignment: CalendarAssignmentProposal,
  options?: CreateAssignmentCalendarLabelOptions,
) {
  const serviceModelLabel = createServiceModelLabel(assignment.service_model);
  const serviceTypesLabel = assignment.service_type_codes
    .map(c => c.toUpperCase())
    .join(', ');
  const specialtiesLabel = assignment.specialties
    .map(s => SPECIALTIES_LONG_NAME[s])
    .join(', ');

  const hours = +dayjs
    .duration(parseDurationString(assignment.hours))
    .asHours()
    .toFixed(2);
  const hoursLabel =
    options?.showHours && `${hours} hour${hours === 1 ? '' : 's'}`;

  const location = assignment.organization.name;

  const details = [
    serviceModelLabel,
    serviceTypesLabel,
    specialtiesLabel,
    assignment.is_assessment && '- Assessments',
    options?.showHours && `| ${hoursLabel}`,
  ]
    .filter(Boolean)
    .join(' ');

  const dates =
    options?.showDates &&
    `${dayjs(assignment.start_date).format('MM/DD/YYYY')} - ${dayjs(
      assignment.end_date,
    ).format('MM/DD/YYYY')}`;

  return [location, details, dates].filter(Boolean).join('\n');
}

export function createEventDedicatedTitle(event: PLEvent) {
  const organizationLabel =
    event.organization_expanded?.name ?? event.locations[0]?.name ?? '';

  if (event.specialty) {
    return `${organizationLabel} - Dedicated (${event.specialty})`;
  }

  return `${organizationLabel} - Dedicated`;
}

export const getOrganizationsFromAssignments = (
  assignments: AssignmentProposal[],
) => {
  return assignments
    .map(assignment => assignment.organization)
    .filter(
      (org, index, self) => self.findIndex(o => o.uuid === org.uuid) === index,
    );
};

export const getServiceTypesFromAssignments = (
  allAssignments: AssignmentProposal[],
  organizationUuid?: string,
) => {
  const serviceTypes = new Set<AssignmentProposal['service_type_codes']['0']>();

  let assignments = [...allAssignments];

  if (organizationUuid) {
    assignments = assignments.filter(
      assignment => assignment.organization.uuid === organizationUuid,
    );
  }

  assignments.forEach(assignment => {
    assignment.service_type_codes.forEach(serviceType =>
      serviceTypes.add(serviceType),
    );
  });

  return Array.from(serviceTypes).sort();
};
