import { Injectable } from '@angular/core';

import { Observable, timer, Subject, of } from 'rxjs';

import { first, switchMap, catchError } from 'rxjs/operators';

import { PLGraphQLService } from '@root/index';

const listTasksQuery = require('./list-tasks.graphql');
const updateTaskOwnerCompleteQuery = require('./update-task-owner-complete.graphql');

export enum TaskType {
  OPEN_REFERRAL_AVAILABLE = 'open_referral_available',
}

@Injectable()
export class PLTasksService {
  private tasksSubject = new Subject();
  private incompleteTasksSubject = new Subject();

  constructor(private plGraphQL: PLGraphQLService) {}

  start() {
    // refresh every 5 minutes
    timer(0, 300000)
      .pipe(
        switchMap(() =>
          this.fetch().pipe(
            catchError(error => {
              return of(null); // return a safe value or Observable to keep the stream alive
            }),
          ),
        ),
      )
      .subscribe((res: any) => {
        if (res !== null) {
          this.notifyObservers(res);
        }
      });
  }

  refresh() {
    this.fetch().subscribe((res: any) => {
      this.notifyObservers(res);
    });
  }

  getTasks(): Observable<any> {
    return this.tasksSubject.asObservable();
  }

  getHasIncompleteTasks(): Observable<any> {
    return this.incompleteTasksSubject.asObservable();
  }

  private fetch() {
    return this.plGraphQL
      .query(listTasksQuery, {}, { suppressError: true }, {}, false)
      .pipe(first());
  }

  private notifyObservers(res: any) {
    this.tasksSubject.next(res);

    const taskStatus = {
      hasTasks: false,
      hasUnreadTasks: false,
    };

    taskStatus.hasTasks =
      res.tasks.filter((x: any) => x.completedOn === null).length > 0;
    taskStatus.hasUnreadTasks =
      res.tasks.filter(
        (x: any) => x.owners.filter((y: any) => y.read === false).length > 0,
      ).length > 0;

    this.incompleteTasksSubject.next(taskStatus);
  }

  updateTaskOwnerComplete(task: any) {
    {
      task.owners.forEach((owner: any) => {
        this.plGraphQL
          .mutate(
            updateTaskOwnerCompleteQuery,
            { id: owner.id, isComplete: true, read: true },
            {},
          )
          .subscribe(() => {
            this.refresh();
          });
      });
    }
  }
}
