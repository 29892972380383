import type { Observable } from 'rxjs';
import { User } from '@modules/user/user.model';

export enum FeatureFlagName {
  payRatesFY24 = 'payRatesFY24',
  showGeneralEducationIndicator = 'showGeneralEducationIndicator',
  newProviderProfile = 'newProviderProfile',
  showNotificationPreferencesWithPhoneNumber = 'showNotificationPreferencesWithPhoneNumber',
  useNewProviderProfile = 'usenewproviderprofile',
  showNewProviderOverviewPage = 'showNewProviderOverviewPage',
  showLastWorkDayField = 'showLastWorkDayField',
  isFY25 = 'isFY25',
  showNewLandingPage = 'showNewLandingPage',
  showReservedAssignment = 'showReservedAssignment',
  aiSummarization = 'aiSummarization',
  newUploadReferral = 'newUploadReferral',
  useAssignmentMarketplace = 'useAssignmentMarketplace',
  onHoldAndBulkActions = 'onHoldAndBulkActions',
  aISessionEventLogs = 'aISessionEventLogs',
  referralDrivenAvailability = 'referralDrivenAvailability',
  selfMatchReferrals = 'self_match_referrals',
}

export enum TextValueName {
  specialtiesDeprecationDate = 'specialtiesDeprecationDate',
  calendarEventsEnabledActions = 'calendarEventsEnabledActions',
}

export type ConfigName = FeatureFlagName | TextValueName;

export type FeatureFlagAll = Partial<Record<FeatureFlagName, unknown>>;

export interface FeatureFlagsCommon {
  identifyUser(user: User): void;
  getAllFeatures(): Observable<FeatureFlagAll>;
  isFeatureEnabled(key: FeatureFlagName): Observable<boolean>;
  getTextValue(key: TextValueName): Observable<string>;
}
