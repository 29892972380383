import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigCatFeatureFlagsService } from './config-cat-feature-flags.service';
import {
  FeatureFlagName,
  FeatureFlagsCommon,
  TextValueName,
} from './feature-flags';
import { User } from '../../modules/user/user.model';

@Injectable({ providedIn: 'root', useClass: ConfigCatFeatureFlagsService })
export class FeatureFlagsService implements FeatureFlagsCommon {
  identifyUser(_user: User): void {}
  getAllFeatures() {
    return of({});
  }
  isFeatureEnabled(_key: FeatureFlagName): Observable<boolean> {
    return of(false);
  }
  getTextValue(_key: TextValueName): Observable<string> {
    return of('');
  }
  getList(_key: TextValueName, _emptyValue = 'NONE'): Observable<string[]> {
    return of([]);
  }
}
