<div class="alert-banner" [ngClass]="type">
  <div class="icon-container" *ngIf="icon">
    <mat-icon class="icon">{{ icon }}</mat-icon>
  </div>
  <div class="alert-content">
    <div class="alert-title" *ngIf="title">{{ title }}</div>
    <ng-content></ng-content>
  </div>
  <div class="alert-actions">
    <button
      mat-raised-button
      color="primary"
      *ngIf="buttonLabel"
      class="action-button"
      (click)="action()"
    >
      <span>{{ buttonLabel }}</span>
    </button>
    <button *ngIf="showCloseButton" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
