import { Pipe, PipeTransform } from '@angular/core';
import { PLAssignmentStatusEnum } from '../../modules/assignment-manager/pl-assignment-manager.model';

/*
    capitalizes the first letter of the input string, lower case after.
*/
@Pipe({
  name: 'plAssigmentStatus',
})
export class PLAssigmentStatusPipe implements PipeTransform {
  transform(value: PLAssignmentStatusEnum): string {
    switch (value) {
      case PLAssignmentStatusEnum.INITIATED:
        return 'PROPOSED';
      case PLAssignmentStatusEnum.PENDING:
        return 'PENDING';
      case PLAssignmentStatusEnum.ACTIVE:
        return 'ACTIVE';
      case PLAssignmentStatusEnum.RESERVED:
      case PLAssignmentStatusEnum.TENTATIVE:
        return 'RESERVED';
    }
  }
}
